import React from "react";
import styled, { css } from "styled-components";
import { graphql, StaticQuery } from "gatsby";

// Services
import intl from "../../services/intl";

// Utils
import { Font, Color, media, rem, responsive } from "../../utils/style";
import metrics from "../../utils/metrics";
import { getDiscountData } from "../../utils/bundle";
import { orderArray } from "../../utils/order";

// Components
import Container from "../Container";
import Img from "gatsby-image";
import Ribbon from "../global/Ribbon";
import ScrollableOverflow from "../ScrollableOverflow";
import MagicLink from "../MagicLink";

const Wrapper = styled.section``;

const CategoryCardsContainer = styled(Container)`
  padding: 0 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: ${p => (p.scrollable ? "nowrap" : "wrap")};

  ${responsive.sm`
    padding: 0px;
  `}
`;

const Slide = styled(MagicLink)`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: calc(100vw * (84/320));
  margin-right: calc(100vw * (8/320));
  background-color: rgba(242, 241, 245, 0.56);

  :last-of-type {
    margin-right: 0;
  }

  p {
    ${Font.circular};
    color: ${Color.ritualBlue};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-weight: 300;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    padding: 6px 0;
  }

  ${responsive.sm`
    width: 160px;
    margin-right: 20px;

    p:before {
      content: "Shop ";
    }

    img {
      transition: transform 0.2s ease-in !important;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  `}

  ${responsive.md`
    width: 214px;
    margin-right: 26px;

    p {
      font-size: ${rem(18)};
      line-height: ${rem(24)};
      padding: 12px 0;
    }
  `}

  ${responsive.lg`
    width: 270px;
    margin-right: 30px;
  `}
`;

const GridSlide = styled(Slide)`
  ${media.mobile`
    width: calc(50% - 7px);
    margin-right: 14px;
    margin-bottom: 16px;

    &:nth-of-type(2n) {
      margin-right: 0;
    }

    &:nth-last-of-type(2):nth-of-type(odd),
    &:last-of-type {
      margin-bottom: 0;
    }
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    background-color: ${Color.ritualYellow};
    height: 52px;
  }

  span {
    position: absolute;
    bottom: 4px;
    left: 4px;
  }


  ${responsive.sm`
    height: 82px;

    span {
      bottom: 8px;
      left: 8px;
    }
  `}

  ${responsive.md`
    height: 110px;

    span {
      bottom: 12px;
      left: 12px;
    }
  `}

  ${responsive.lg`
    height: 140px;
  `}
`;

const RibbonStyle = css`
  height: 20px;
  padding: 0px 8px;
  font-size: ${rem(8)};
  line-height: ${rem(16)};
  letter-spacing: 0.53px;

  &::before,
  &::after {
    border-width: 10px 4px;
  }

  ${responsive.md`
    height: 30px;
    padding: 0 14px;
    font-size: ${rem(10)};
    letter-spacing: 0.67px;

    &::before,
    &::after {
      border-width: 15px 8px;
    }
  `}
`;

const OrderedCardShortNames = [
  "44TmdusDzEH95uGu0URjIr", // Women
  "71526baqpQqVsScHBZpIEl", // Men
  "16dqMm2vfVsgy539guPkkE", // Kids & Teens
];

export class ProductCategoryCards extends React.Component {
  constructor(props) {
    super(props);

    const categories = intl.cf(props.data.allContentfulProductCategory.nodes);
    this.orderedCategoryNodes = this._getOrderedCategories(categories);
  }

  handleClick(name) {
    metrics.track("CTA Clicked", {
      title: name,
      location: "Product Category Card",
      nonInteraction: false,
    });
  }

  _getOrderedCategories(allCategoryNodes) {
    return orderArray(allCategoryNodes, OrderedCardShortNames, "contentful_id");
  }

  renderSlides(slides, scrollable) {
    const SlideComponent = scrollable ? Slide : GridSlide;

    return (
      <>
        {slides.map((slide, i) => {
          return (
            <SlideComponent
              to={slide.url}
              onClick={this.handleClick.bind(this, slide.title)}
              key={i}
            >
              <ImageContainer>
                <Img
                  fluid={slide.img.fluid}
                  loading="eager"
                  backgroundColor={Color.ritualYellow}
                  alt={slide.altText}
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                  imgStyle={{
                    objectPosition: "top center",
                  }}
                />
                {slide.flag && (
                  <Ribbon contentStyle={RibbonStyle} white={true}>
                    {slide.flag}
                  </Ribbon>
                )}
              </ImageContainer>
              <p>{slide.title}</p>
            </SlideComponent>
          );
        })}
      </>
    );
  }

  render() {
    const { data, className, scrollable = false } = this.props;

    const bundleImage = intl.cf(data.bundleImages.nodes, true);
    const discountData = getDiscountData();

    const slides = this.orderedCategoryNodes.map(node => {
      const flag = node.shortName === "Kids" ? "New" : "";
      return {
        title: node.shortName,
        url: `/${node.shopLandingReference.slug}`,
        altText: `Shop ${node.shortName}`,
        img: node.cardImage,
        flag,
      };
    });

    slides.push({
      title: intl.t("product.category-cards.slide-bundles", "Bundles"),
      url: "/shop-bundles",
      altText: "Shop Bundles",
      img: bundleImage,
      flag: discountData.titleCopy,
    });

    return (
      <Wrapper className={className}>
        <CategoryCardsContainer scrollable={scrollable}>
          {scrollable && (
            <ScrollableOverflow bleedLeft={true} bleedRight={true}>
              {this.renderSlides(slides, scrollable)}
            </ScrollableOverflow>
          )}
          {!scrollable && <>{this.renderSlides(slides, scrollable)}</>}
        </CategoryCardsContainer>
      </Wrapper>
    );
  }
}

export const ProductCategoryCardsQuery = props => (
  <StaticQuery
    query={componentQuery}
    render={data => <ProductCategoryCards {...props} data={data} />}
  />
);

export default ProductCategoryCardsQuery;

const componentQuery = graphql`
  query ProductCategoryCardsQuery {
    bundleImages: allContentfulAsset(
      filter: { contentful_id: { eq: "2RQ7pdzdW9JmLk0z0shhzM" } }
    ) {
      nodes {
        node_locale
        title
        description
        fluid(maxWidth: 580, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    allContentfulProductCategory {
      nodes {
        node_locale
        shortName
        contentful_id
        cardImage {
          title
          description
          fluid(maxWidth: 580, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        backgroundColor
        shopLandingReference {
          slug
          header {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  }
`;
