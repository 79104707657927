import React from "react";
import styled from "styled-components";
import { media, Color } from "../../utils/style";
import { PressLogos } from "../../utils/svg";
import Container from "../Container";
import Row from "../Row";
import MagicLink from "../MagicLink";

const PressWrapper = styled.div.attrs({ id: "press" })`
  position: relative;
  background-color: ${p => (p.background ? p.background : "transparent")};
`;

const PressContainer = styled(Container).attrs({
  id: "press_container",
})`
  position: relative;
  overflow: visible !important;
`;

const StyledContainer = styled(Container).attrs({
  id: "press_styled-container",
})`
  position: absolute;
  overflow: visible !important;
  height: 100%;
  max-width: none !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 !important;
  padding: 0 !important;
`;

const PressColumn = styled.div.attrs({
  id: "press_container_row_column",
  className: "col-12 col-md-8 offset-md-2",
})`
  position: relative;
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 56px;
  padding-bottom: 120px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 4px;
    background-color: ${Color.ritualBlue};

    ${media.mobile`
      right: 16px;
      left: 16px;
    `};
  }

  ${media.tablet`
    padding-bottom: 80px;
  `} ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 80px;
    padding-top: 40px;
  `};
`;

const PressIcon = styled.div.attrs(p => ({
  id: `press_container_row_column_icon-${p.index}`,
}))`
  position: relative;
  margin: 0;
  flex: 0 1 auto;
  height: 40px;
  &:last-of-type {
    margin: 0;
  }
  ${media.mobile`
    margin: 0 0 40px 0;
  `} svg {
    height: 40px;
    color: ${Color.ritualBlue};
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    &:hover {
      opacity: 0.56;
    }
    path,
    g {
      fill: currentColor;
    }
  }
`;

export default class Press extends React.Component {
  render() {
    let { quotes, children, background } = this.props;

    return (
      <PressWrapper background={background}>
        <PressContainer>
          <Row id="press_container_row">
            <PressColumn>
              {quotes.map((quote, i) => {
                let Icon = PressLogos[quote.icon];
                if (quote.link) {
                  return (
                    <PressIcon index={i} key={i}>
                      <MagicLink
                        id={`press_container_row_column_icon-${i}_link`}
                        className={`press__icon__${quote.icon}`}
                        href={quote.link}
                        ariaLabel={quote.author}
                        rel="noopener noreferrer"
                      >
                        <Icon
                          id={`press_container_row_column_icon-${i}_link_svg`}
                        />
                      </MagicLink>
                    </PressIcon>
                  );
                }
                return (
                  <PressIcon index={i} key={i}>
                    <Icon
                      id={`press_container_row_column_icon-${i}_link_svg`}
                    />
                  </PressIcon>
                );
              })}
            </PressColumn>
          </Row>
        </PressContainer>
        <StyledContainer>{children}</StyledContainer>
      </PressWrapper>
    );
  }
}
