import React from "react";
import InstagramSimpleImage from "./InstagramSimpleImage";
import Container from "./Container";
import Text from "./Text";
import { Color, rem, media, Font, responsive } from "../utils/style";
import styled from "styled-components";

const OuterWrapper = styled.section`
  position: relative;
  overflow: hidden;
  padding: ${props => (props.noTopMargin ? "0 0 80px" : "80px 0")};
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : "rgba(242, 241, 245, 0.56)"};

  ${responsive.md`
    padding: 120px 0;
  `}
`;

const StyledContainer = styled(Container)`
  overflow: visible !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 !important;
  margin: 0 !important;
`;

const CommunityArea = styled.div`
  position: relative;
  z-index: 100;
`;

const Anchor = styled.a`
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;

const FeedTitle = styled.h2`
  ${Font.circular}
  color: ${Color.ritualBlue};
  text-align: center !important;
  margin-bottom: 24px;
  font-size: ${rem(24)};
  font-weight: 500;
  letter-spacing: -1.4px;
  ${responsive.sm`
    font-size: ${rem(30)};
  `}
  ${responsive.md`
    font-size: ${rem(48)};
  `}
  ${responsive.lg`
  font-size: ${rem(40)};
`}
`;

const FeedSubtitle = styled.div`
  ${Font.dutch}
  color: ${Color.ritualBlue};
  margin-bottom: 56px;
  font-size: ${rem(18)};
  text-align: center;
  ${media.tablet`
    font-size: ${rem(16)};
    letter-spacing: -0.4px;
    line-height: ${rem(26)};
    margin-bottom: 40px;
  `}

  u {
    position: relative;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: -3px;
      background: ${Color.ritualBlue};
    }
  }
`;

const SimpleImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 5px;
  margin-right: 5px;
  ${responsive.sm`
    margin-left: -15px;
    margin-right: -15px;
  `}
`;

export default class InstagramShowcase extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, images, noTopMargin, backgroundColor } = this.props;

    return (
      <OuterWrapper backgroundColor={backgroundColor} noTopMargin={noTopMargin}>
        <StyledContainer>{children}</StyledContainer>
        <Container>
          <CommunityArea className="row">
            <div className="col-12">
              <Anchor
                href="https://www.instagram.com/ritual"
                hrefLang="x-default"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FeedTitle>
                  <Text
                    id="instagram.feed-title"
                    defaultMessage="Make Your Self"
                  />
                </FeedTitle>
                <FeedSubtitle>
                  <Text
                    id="instagram.feed-subtitle"
                    defaultMessage="Commit to yourself every single day with {link}"
                    values={{ link: <u>@Ritual</u> }}
                  />
                </FeedSubtitle>
              </Anchor>
              <SimpleImageContainer>
                {images.map((image, index) => {
                  return <InstagramSimpleImage key={index} image={image} />;
                })}
              </SimpleImageContainer>
            </div>
          </CommunityArea>
        </Container>
      </OuterWrapper>
    );
  }
}
