import React from "react";
import styled from "styled-components";
import { rem, media, responsive } from "../../utils/style";
import Container from "../Container";
import Row from "../Row";
import { graphql, StaticQuery } from "gatsby";
import ValueProps from "../../components/product/ValueProps";

// Services
import intl from "../../services/intl";

const componentQuery = graphql`
  query IntroductionComponentQuery {
    allContentfulHomePage {
      nodes {
        node_locale
        formsIcons
        formsLabels
        introText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

const IntroContainer = styled(Container).attrs({
  id: "introduction-container",
})`
  position: relative;
  z-index: 2;
  margin-top: 40px;
  margin-bottom: 80px;

  ${media.mobile`
    padding: 0 20px;
  `}

  ${responsive.sm`
    margin-top: 48px;
    margin-bottom: 80px;
  `};

  ${responsive.md`
    margin-top: 80px;
    margin-bottom: 120px;
  `};
`;

const IntroColumn = styled.div.attrs({
  id: "introduction-container_row_container_intro-column",
  className: "col-12 col-sm-8 offset-sm-2 col-lg-10 offset-lg-1",
})`
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
`;

const IntroHeader = styled.h2.attrs({
  id: "introduction-container_row_container_intro-column_title",
})`
  position: relative;
  text-align: center !important;
  margin: 0;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 500;
  letter-spacing: -0.2px;

  ${media.mobile`
    text-align: left !important;
  `};
`;

export const IntroductionComponent = class Introduction extends React.Component {
  render() {
    const data = intl.cf(this.props.data.allContentfulHomePage.nodes, true);

    return (
      <IntroContainer>
        <Row id="introduction-container_row">
          <IntroColumn>
            <IntroHeader
              dangerouslySetInnerHTML={{
                __html: data.introText.childMarkdownRemark.html
                  .replace("<p>", "")
                  .replace("</p>", ""),
              }}
            />
          </IntroColumn>
          <ValueProps
            valueProps={data.formsLabels}
            icons={data.formsIcons}
            className="col-md-10 col-lg-8 offset-md-1 offset-lg-2"
            removePadding={true}
          />
        </Row>
      </IntroContainer>
    );
  }
};

export const IntroductionComponentQuery = props => (
  <StaticQuery
    query={componentQuery}
    render={data => <IntroductionComponent {...props} data={data} />}
  />
);

export default IntroductionComponentQuery;
