import metrics from "./metrics";

export function setupTalkableHooks(talkableHooks) {
  for (let talkableHook of talkableHooks) {
    console.log("Talkable Hook setup: ", talkableHook);
    window.talkable.subscribe(talkableHook, "talkable-offer", () => {
      console.log("Talkable Hook fired: ", talkableHook);
      metrics.track("Talkable iFrame", {
        name: talkableHook,
      });
    });
  }
}
