import React from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";

// Utils
import { setupTalkableHooks } from "../../utils/talkable";
import { rem, responsive, Color } from "../../utils/style";
import metrics from "../../utils/metrics";

// Components
import RightArrow from "../../../static/svg/arrow-rounded-right.svg";
import Ribbon from "../../components/global/Ribbon";

// Hero Component
import PageHero from "../../components/PageHero";
import HeroContent from "../../components/home/HeroContent";

// Press Component
import Press from "../../components/home/Press";

// Testimonials Component
import Testimonials from "../../components/testimonials/Testimonials";

// Instagram Component
import InstagramShowcase from "../../components/InstagramShowcase";

// Introduction Component
import Introduction from "../../components/home/Introduction";

// Featured Area Component
import FeaturedArea from "../../components/home/FeaturedArea.New";

// Other Components
import PageSEO from "../../components/seo/Page";
import CircleNew from "../../components/home/Circle.New";
import MagicLink from "../../components/MagicLink";
import ProductCategoryCards from "../../components/product/ProductCategoryCards";
import Text from "../../components/Text";

const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const CircleContainer = styled.div.attrs({
  className: "container",
})`
  position: relative;
  overflow: visible !important;
  padding: 0 !important;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledMagicLink = styled(MagicLink)`
  margin-bottom: 0px !important;
`;

const ProductsLinks = [
  {
    link: "/products/essential-for-women-multivitamin",
    text: "Multivitamin for Women 18+",
    icon: false,
  },
  {
    link: "/products/essential-prenatal-multivitamin",
    text: "The Prenatal Multivitamin",
    icon: false,
  },
  {
    link: "/products/essential-multivitamin-for-men",
    text: "Multivitamin for Men 18+",
    icon: false,
  },
  {
    link: "/products/essential-multivitamin-for-kids",
    text: "Multivitamin for Kids 4+",
    icon: false,
    flag: true,
  },
];

const NewRibbonWrapper = styled(Ribbon)`
  margin-left: 15px;
`;

const PressWrapper = styled.div`
  background: rgba(242, 241, 245, 0.56);

  ${responsive.md`
    padding-top: 80px;
  `}
`;

function MagicLinkRow({ link, text, icon, navigate, flag }) {
  return (
    <StyledRow>
      <StyledMagicLink className="-underline" to={link}>
        {text}
      </StyledMagicLink>
      {flag && <NewRibbonWrapper>New</NewRibbonWrapper>}
      {icon && (
        <RightArrow
          onClick={() => navigate(link)}
          style={{ marginLeft: ".5em" }}
        />
      )}
    </StyledRow>
  );
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    let {
      pageTitle,
      pageDescription,
      socialImage,
    } = props.data.allContentfulHomePage.edges[0].node;
    this.state = {
      talkableHooksArray: [
        "coupon_issued",
        "share_succeeded",
        "email_gating_passed",
        "email_gating_failed",
        "offer_loaded",
        "offer_triggered",
      ],
      seo: {
        pagePath: "",
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
      },
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Home",
    });

    this._setNavColor();

    window._talkableq.push(["register_affiliate", {}]);
    const talkablePresent = typeof window.talkable !== "undefined";

    if (talkablePresent) {
      setupTalkableHooks(this.state.talkableHooksArray);
    }
  }

  _setNavColor() {
    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: true,
    });
  }

  trackCTAClick(title, location, url, e) {
    e.preventDefault();
    metrics.track("CTA Clicked", {
      title: title,
      nonInteraction: false,
      location: location,
    });
    navigate(url);
  }

  render() {
    const { seo } = this.state;
    const { navigate } = this.props;
    const contentfulData = this.props.data;
    const clickableFeaturedData = ProductsLinks;

    return (
      <>
        <PageSEO {...seo} />
        <PageContent
          contentfulData={contentfulData}
          navigate={navigate}
          clickableFeaturedData={clickableFeaturedData}
        />
      </>
    );
  }
}

function PageContent({ contentfulData, navigate, clickableFeaturedData }) {
  const {
    featuredArea1,
    featuredArea1Image,
    featuredArea1BackgroundColor,
    featuredArea2,
    featuredArea2Image,
    featuredArea2BackgroundColor,
    featuredArea3,
    featuredArea3List,
    featuredArea3Image,
    featuredArea3BackgroundColor,
    pressQuotes,
    instagramImages,
    heroImage,
    heroImageMobile,
    heroBackgroundColor,
  } = contentfulData.allContentfulHomePage.edges[0].node;

  return (
    <>
      <PageHero
        backgroundColor={heroBackgroundColor}
        imageMobile={heroImageMobile}
        imageDesktop={heroImage}
      >
        <HeroContent />
      </PageHero>
      <ProductCategoryCards
        className="mt-4--5 mt-sm-5 mt-md-6--5"
        scrollable={true}
      />
      <ContentWrapper>
        <Introduction />
        <CircleContainer>
          <CircleNew className="left centerY" />
          <FeaturedArea
            indexId="0"
            align="right"
            image={featuredArea1Image}
            background={featuredArea1BackgroundColor}
            markdown={featuredArea1.childMarkdownRemark.html}
          >
            {clickableFeaturedData.map((key, index) => {
              return (
                <MagicLinkRow
                  navigate={navigate}
                  key={index}
                  link={key.link}
                  text={key.text}
                  icon={key.icon}
                  flag={key.flag}
                />
              );
            })}
          </FeaturedArea>
        </CircleContainer>
        <FeaturedArea
          align="left"
          indexId="1"
          image={featuredArea2Image}
          background={featuredArea2BackgroundColor}
          markdown={featuredArea2.childMarkdownRemark.html}
        >
          <MagicLink className="-underline" to="/approach">
            <Text id="nav.links.approach" defaultMessage="Who We Are" />
          </MagicLink>
          <MagicLink className="-underline" to="/ingredients">
            <Text
              id="nav.links.meet-ingredients"
              defaultMessage="Meet Our Ingredients"
            />
          </MagicLink>
          <MagicLink className="-underline" to="/clinical-study">
            <Text
              id="nav.links.clinical-study"
              defaultMessage="Our Clinical Study"
            />
          </MagicLink>
        </FeaturedArea>
        <FeaturedArea
          indexId="2"
          list={featuredArea3List}
          icons={["Balloon", "Recurring", "Cancel", "CircleStar"]}
          align="right"
          image={featuredArea3Image}
          background={featuredArea3BackgroundColor}
          markdown={featuredArea3.childMarkdownRemark.html}
        />
        <Testimonials paddingBottom={0} />
        <PressWrapper>
          <Press quotes={pressQuotes}>
            <CircleNew className={"right bottom obscured"} />
          </Press>
        </PressWrapper>
      </ContentWrapper>
      <InstagramShowcase images={instagramImages} backgroundColor={Color.white}>
        <CircleNew className={"top right"} />
      </InstagramShowcase>
    </>
  );
}

export const pageQuery = graphql`
  query HomePageQuery($locale: String!) {
    allContentfulHomePage(
      filter: {
        node_locale: { eq: $locale }
        contentful_id: { eq: "6jLz5EhYUoiwGqKUmQgAms" }
      }
    ) {
      edges {
        node {
          pageTitle
          pageDescription
          socialImage {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          featuredArea1 {
            childMarkdownRemark {
              html
            }
          }
          featuredArea1Image {
            file {
              url
            }
            fluid(maxWidth: 570, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          featuredArea1BackgroundColor
          featuredArea2 {
            childMarkdownRemark {
              html
            }
          }
          featuredArea2Image {
            file {
              url
            }
            fluid(maxWidth: 570, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          featuredArea2BackgroundColor
          featuredArea3 {
            childMarkdownRemark {
              html
            }
          }
          featuredArea3List
          featuredArea3Image {
            file {
              url
            }
            fluid(maxWidth: 570, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          featuredArea3BackgroundColor
          products {
            ...ProductCardFragment
          }
          instagramImages {
            title
            description
            fixed(width: 270, height: 270, quality: 90) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
          }
          pressQuotes {
            author
            icon
            link
          }
          heroImage {
            file {
              url
            }
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          heroImageMobile {
            file {
              url
            }
            fluid(maxWidth: 912, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          heroBackgroundColor
        }
      }
    }
  }
`;
