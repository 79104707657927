import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";

// Services
import intl from "../../services/intl";

// Utils
import { rem, Font, media, Color, responsive } from "../../utils/style";
import metrics from "../../utils/metrics";
import { variation } from "../../utils/launchDarkly";

// Components
import RitualButton from "../global/RitualButton";

// Component Query
const componentQuery = graphql`
  query MensHeroContent {
    allContentfulHomePage {
      nodes {
        node_locale
        heroTitle {
          heroTitle
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

// Styled Elements
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HeaderTitle = styled.h1`
  ${Font.circular};
  text-align: center !important;
  font-size: ${rem(76)};
  line-height: ${rem(72)};
  letter-spacing: -2.3px;
  color: ${Color.ritualBlue};
  margin: 0 auto 16px;
  max-width: 620px;

  em {
    ${Font.dutch};
  }

  p {
    margin: 0;
  }

  ${media.notDesktop`
    font-size: ${rem(66)};
    max-width: 613px;
  `};

  ${media.mobile`
    font-size: ${rem(48)};
    letter-spacing: -1.4px;
    line-height: ${rem(48)};
    max-width: 250px;
  `};

  @media (max-width: 373px) {
    font-size: ${rem(39)};
    font-weight: 500;
    line-height: ${rem(43)};
    letter-spacing: -0.57px;
    max-width: 200px;
  }

  ${responsive.md`
    margin-bottom: 40px;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const ShopButtonWrapper = styled.div`
  margin-right: 24px;

  &:last-of-type {
    margin-right: 0;
  }

  ${responsive.sm`
    margin-right: 40px;
  `}
`;

const ShopButton = styled(RitualButton)`
  width: 100%;
`;

const Buttons = [
  {
    title: intl.t("hero.shopall", "Shop All"),
    shortTitle: intl.t("hero.shopall", "Shop All"),
    to: "/shop-vitamins",
  },
];

const BundleBuilderButtons = [
  {
    title: intl.t("hero.build-bundle", "Find Your Multivitamin"),
    shortTitle: intl.t("hero.build-bundle", "Find Your Multivitamin"),
    to: "/build-a-bundle",
  },
];

export class HeroContent extends React.Component {
  constructor() {
    super();

    this.state = {
      isBundleBuilderHero: false,
    };
  }

  componentDidMount() {
    const isBundleBuilderHero = variation("bundle-builder-hero");

    this.setState({
      isBundleBuilderHero,
    });
  }

  handleClick(title) {
    metrics.track("CTA Clicked", {
      location: "Hero",
      title,
      nonInteraction: false,
    });
  }

  render() {
    const { heroTitle } = intl.cf(
      this.props.data.allContentfulHomePage.nodes,
      true,
    );
    const { isBundleBuilderHero } = this.state;

    const cleanHtml = heroTitle.childMarkdownRemark.html
      .replace("<p>", "")
      .replace("</p>", "");

    const buttons = isBundleBuilderHero ? BundleBuilderButtons : Buttons;

    return (
      <Wrapper>
        <HeaderTitle
          dangerouslySetInnerHTML={{
            __html: cleanHtml,
          }}
        />

        {/* Rendered on XS breakpoints */}
        <ButtonsWrapper>
          {buttons.map((button, index) => (
            <ShopButtonWrapper key={index} className="d-block d-sm-none">
              <ShopButton
                to={button.to}
                onClick={this.handleClick.bind(this, button.shortTitle)}
                title={button.shortTitle}
                className="short alt-hover"
              >
                {button.shortTitle}
              </ShopButton>
            </ShopButtonWrapper>
          ))}
        </ButtonsWrapper>

        {/* Rendered on S, M and L breakpoints */}
        <ButtonsWrapper>
          {buttons.map((button, index) => (
            <ShopButtonWrapper key={index} className="d-none d-sm-block">
              <ShopButton
                to={button.to}
                onClick={this.handleClick.bind(this, button.title)}
                title={button.title}
                className="alt-hover"
              >
                {button.title}
              </ShopButton>
            </ShopButtonWrapper>
          ))}
        </ButtonsWrapper>
      </Wrapper>
    );
  }
}

export const HeroContentComponentQuery = props => (
  <StaticQuery
    query={componentQuery}
    render={data => <HeroContent {...props} data={data} />}
  />
);

export default HeroContentComponentQuery;
